import CustomButton from '@components/Button';
import { Box, Divider, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';

export default function ReplacementItems(props: { setSteps: any }) {
  const { setSteps } = props;
  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
      <Typography sx={{ fontSize: '24px', fontWeight: 'bold' }}>
        Choose a replacement for this order
      </Typography>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue={1}
        name="radio-buttons-group"
        sx={{ width: '100%', mt: 5 }}>
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            justifyContent: 'space-between',
            alignItems: 'center',
            py: 3
          }}>
          <Typography sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <Radio value={1} />
            <Typography>
              <Typography sx={{ fontSize: 16, fontWeight: 'bold', color: 'grey' }}>
                Frozen Yogurt
              </Typography>
              <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>
                Acai strawberry sorbet
              </Typography>
            </Typography>
          </Typography>
          <Typography sx={{ fontSize: 14, fontWeight: 'bold', color: 'grey' }}>$7.50</Typography>
        </Box>

        <Divider sx={{ width: '100%' }} />

        <Box
          sx={{
            display: 'flex',
            gap: 2,
            justifyContent: 'space-between',
            alignItems: 'center',
            py: 3
          }}>
          <Typography sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <Radio value={2} />
            <Typography>
              <Typography sx={{ fontSize: 16, fontWeight: 'bold', color: 'grey' }}>
                Frozen Yogurt
              </Typography>
              <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>Salted caramel</Typography>
            </Typography>
          </Typography>
          <Typography sx={{ fontSize: 14, fontWeight: 'bold', color: 'grey' }}>$7.50</Typography>
        </Box>
      </RadioGroup>
      <Box
        sx={{
          mt: 5,
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
          gap: 2,
          width: '100%'
        }}>
        <CustomButton
          sx={{
            width: '150px',
            background: 'lightgrey',
            color: '#000'
          }}
          variant="contained">
          Back
        </CustomButton>
        <CustomButton
          sx={{ width: '150px' }}
          variant="contained"
          color="primary"
          onClick={() => setSteps(4)}>
          Next
        </CustomButton>
      </Box>
    </Box>
  );
}
