import CustomButton from '@components/Button';
import Modal from '@components/Modal';
import {
  Divider,
  FormControl,
  FormControlLabel,
  Grid2,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MenuItem } from 'types/services';

export type TimeType = 'fourHours' | 'endOfDay' | 'indefinitely' | '';

export default function OutOfStockModal({
  isOpen,
  setIsOpen,
  selectedItem,
  onClickApply,
  loading
}: {
  selectedItem: MenuItem;
  isOpen: boolean;
  setIsOpen: (status: boolean) => void;
  onClickApply: (time: TimeType) => void;
  loading?: boolean;
}) {
  const [time, setTime] = useState<TimeType>('');
  const timeAfter4Hours = moment().add(4, 'hours').format('h:mm A');

  return (
    <Modal
      loading={loading}
      title={<strong>{`How long should ${selectedItem?.name} be out of stock?`}</strong>}
      open={isOpen}
      closeModal={() => setIsOpen(false)}
      content={
        <>
          <Grid2
            container
            justifyContent="center"
            sx={{
              padding: '20px',
              width: '100%'
            }}>
            <FormControl sx={{ width: '100%' }}>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group">
                <Grid2
                  container
                  width={'100%'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  size={12}>
                  <FormControlLabel
                    control={
                      <Radio checked={time === 'fourHours'} onChange={() => setTime('fourHours')} />
                    }
                    label="Four Hours"
                  />
                  <Typography
                    component="div"
                    sx={{
                      fontSize: '16px',
                      fontWeight: '600'
                    }}>
                    Until {timeAfter4Hours}
                  </Typography>
                </Grid2>
                <Divider />
                <Grid2
                  container
                  width={'100%'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  size={12}>
                  <FormControlLabel
                    control={
                      <Radio checked={time === 'endOfDay'} onChange={() => setTime('endOfDay')} />
                    }
                    label="End of Day"
                  />
                  <Typography
                    component="div"
                    sx={{
                      fontSize: '16px',
                      fontWeight: '600'
                    }}>
                    Until 11:59 PM
                  </Typography>
                </Grid2>
                <Divider />
                <Grid2
                  container
                  width={'100%'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  size={6}>
                  <FormControlLabel
                    control={
                      <Radio
                        checked={time === 'indefinitely'}
                        onChange={() => setTime('indefinitely')}
                      />
                    }
                    label="Indefinitely"
                  />
                  <Typography
                    component="div"
                    sx={{
                      fontSize: '16px',
                      fontWeight: '600'
                    }}>
                    Until manually reactivated
                  </Typography>
                </Grid2>
              </RadioGroup>
            </FormControl>
          </Grid2>
        </>
      }
      actions={
        <>
          <Grid2 container width={'100%'} justifyContent={'end'} alignItems={'center'} size={12}>
            <Typography component="div">
              <CustomButton
                default
                variant="contained"
                onClick={() => setIsOpen(false)}
                sx={{ mr: 2 }}>
                Cancel
              </CustomButton>
              <CustomButton variant="contained" color="primary" onClick={() => onClickApply(time)}>
                Apply
              </CustomButton>
            </Typography>
          </Grid2>
        </>
      }
      fullScreen={false}
      maxWidth="sm"
    />
  );
}
