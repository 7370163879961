import CustomButton from '@components/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Divider, Grid2, IconButton, Input, Typography } from '@mui/material';
import { useState } from 'react';

export default function ConfirmHours(props: {
  setshowAddSpecialHoursModal: any;
  selectedDate: any;
  onConfirm: () => void;
  setStep: any;
}) {
  const { setshowAddSpecialHoursModal, onConfirm, setStep, selectedDate } = props;

  const [storeOpenTime, setStoreOpenTime] = useState<string>('');
  const [storeCloseTime, setStoreCloseTime] = useState<string>('');
  const [isTimeConfirm, setIsTimeConfirm] = useState(false);

  function convertToAMPM(time: string): string {
    const [hours, minutes] = time.split(':').map(Number);
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const adjustedHours = hours % 12 || 12;
    return `${adjustedHours}:${minutes.toString().padStart(2, '0')} ${ampm}`;
  }

  return (
    <Box
      sx={{
        width: '100%',
        mt: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 4
      }}>
      <Typography
        sx={{
          fontSize: 18,
          fontWeight: 'bold'
        }}>
        Set your special hours below
      </Typography>
      <Grid2 container alignItems={'center'} spacing={2} sx={{ mt: 3 }}>
        <Grid2 size={3}>
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: 'bold'
            }}>
            Dates
          </Typography>
        </Grid2>
        <Grid2 size={4}>
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: 'bold'
            }}>
            Store Open
          </Typography>
        </Grid2>
        <Grid2 size={5}>
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: 'bold'
            }}>
            Store Close
          </Typography>
        </Grid2>
        <Divider sx={{ width: '100%' }} />
        <Grid2 size={3}>
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: 'bold',
              color: 'grey'
            }}>
            {selectedDate.date + ''}
          </Typography>
        </Grid2>
        <Grid2 size={4}>
          {!isTimeConfirm ? (
            <Input
              type="time"
              disableUnderline
              style={{
                width: '200px',
                paddingBlock: '10px',
                paddingInline: '10px',
                border: '2px solid lightgrey',
                borderRadius: '4px'
              }}
              onChange={(e) => {
                setStoreOpenTime(e.target.value);
              }}
            />
          ) : (
            <Typography sx={{ fontSize: 16, fontWeight: 'bold', color: 'grey' }}>
              {convertToAMPM(storeOpenTime)}
            </Typography>
          )}
        </Grid2>
        <Grid2
          size={5}
          sx={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            gap: 2
          }}>
          {!isTimeConfirm ? (
            <Input
              type="time"
              disableUnderline
              style={{
                width: '200px',
                paddingBlock: '10px',
                paddingInline: '10px',
                border: '2px solid lightgrey',
                borderRadius: '4px'
              }}
              onChange={(e) => {
                setStoreCloseTime(e.target.value);
              }}
            />
          ) : (
            <Typography sx={{ fontSize: 16, fontWeight: 'bold', color: 'grey' }}>
              {convertToAMPM(storeCloseTime)}
            </Typography>
          )}
          {!isTimeConfirm && (
            <IconButton disabled={!storeOpenTime || !storeCloseTime}>
              <AddCircleOutlineIcon
                sx={{
                  fontSize: 25,
                  color: !storeOpenTime || !storeCloseTime ? '#bebebe' : '#475EDC'
                }}
                onClick={() => setIsTimeConfirm(true)}
              />
            </IconButton>
          )}
        </Grid2>
      </Grid2>

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
          gap: 2,
          mt: 5
        }}>
        <CustomButton
          default
          variant="contained"
          sx={{ px: 3 }}
          onClick={() => setStep('Date Selection')}>
          Back
        </CustomButton>
        <CustomButton
          sx={{ px: 3 }}
          variant="contained"
          color="primary"
          onClick={() => {
            onConfirm();
            setshowAddSpecialHoursModal(false);
          }}>
          Confirm Special Hours
        </CustomButton>
      </Box>
    </Box>
  );
}
