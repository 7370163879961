export const getValidations = () => ({
  messages: {
    required: 'This is required'
  }
});

export const getRestaurantId = () => {
  return localStorage.getItem('restaurantId') || '';
};

export const capitalizeFirstChar = (str: string) => {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getToken = () => {
  return localStorage.getItem('token') || '';
};
