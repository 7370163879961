import CustomButton from '@components/Button';
import Modal from '@components/Modal';
import React, { useState } from 'react';

import ConfirmHours from './ConfirmHours';
import DateSelection from './DateSelection';

export default function AddSpecialHoursModal(props: {
  showAddSpecialHoursModal: boolean;
  setshowAddSpecialHoursModal: any;
  onConfirm: () => void;
}) {
  const { showAddSpecialHoursModal, setshowAddSpecialHoursModal, onConfirm } = props;
  const [step, setStep] = useState<string>('Date Selection');

  const [selectedDate, setSelectedDate] = useState<any>({
    date: '',
    status: ''
  });

  return (
    <Modal
      title={<strong>Add Special Hours or Closures</strong>}
      open={showAddSpecialHoursModal}
      closeModal={() => {
        setshowAddSpecialHoursModal(false);
      }}
      maxWidth="md"
      fullScreen={false}
      content={
        <React.Fragment>
          {step === 'Date Selection' ? (
            <DateSelection
              setshowAddSpecialHoursModal={setshowAddSpecialHoursModal}
              showAddSpecialHoursModal={showAddSpecialHoursModal}
              setStep={setStep}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
            />
          ) : (
            <ConfirmHours
              selectedDate={selectedDate}
              setshowAddSpecialHoursModal={setshowAddSpecialHoursModal}
              onConfirm={onConfirm}
              setStep={setStep}
            />
          )}
        </React.Fragment>
      }
    />
  );
}
