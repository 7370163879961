import { Typography } from "@mui/material";
import React from "react";

export default function Container(props: { children: React.ReactNode }) {
    return (
        <Typography
            component="div"
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ height: "100vh" }}
        >
            {props.children}
        </Typography>
    );
}
