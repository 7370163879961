import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import type { CommonSlice } from './commonSlice';
import { commonSlice } from './commonSlice';
import type { RestaurantSlice } from './restaurantSlice';
import { restaurantSlice } from './restaurantSlice';
import type { UserSlice } from './userSlice';
import { userSlice } from './userSlice';

const useCommonStore = create<CommonSlice>()(
  persist(commonSlice, {
    name: 'commonStore',
    getStorage: () => localStorage
  })
);

const useUserStore = create<UserSlice>()(
  persist(userSlice, {
    name: 'userStore',
    getStorage: () => localStorage
  })
);

const useRestaurantStore = create<RestaurantSlice>()(
  persist(restaurantSlice, {
    name: 'restaurantStore',
    getStorage: () => localStorage
  })
);

const myWindow: any = window;
myWindow.store = {
  useCommonStore,
  useUserStore,
  useRestaurantStore
};

export { useCommonStore, useRestaurantStore, useUserStore };
