import CustomeButton from '@components/Button';
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';
import { Box, Typography } from '@mui/material';

export default function OrderHasBeenCancelled(props: { setSteps: any }) {
  const { setSteps } = props;

  const orderItems = [
    {
      name: 'Chicken Burger',
      quantity: 1,
      price: 10,
      seletedMenuName: 'Test'
    },
    {
      name: 'Chicken Burger',
      quantity: 1,
      price: 15,
      seletedMenuName: 'Fast Food'
    },
    {
      name: 'Chiken Burger',
      quantity: 2,
      price: 16,
      seletedMenuName: 'Lunch'
    }
  ];
  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
      <Typography sx={{ fontSize: '24px', fontWeight: 'bold' }}>
        Order has been cancelled
      </Typography>
      <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: 'grey', mt: 1 }}>
        Test requested that the full order be cancelled if there is something that is out of stock
        on this item.
      </Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', mt: 5 }}>
        {orderItems?.map((item, i) => (
          <Box
            key={i}
            sx={{
              width: '100%',
              py: 2,
              borderBottom: orderItems.length - 1 !== i ? '1px solid lightgrey' : 'none'
            }}>
            <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: 'grey', width: '100%' }}>
              {item.seletedMenuName}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 2,
                width: '100%'
              }}>
              <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                {item.quantity} x {item.name}
              </Typography>
              <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>${item.price}</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 1,
                width: '150px',
                py: 1,
                bgcolor: 'lightgrey',
                mt: 3,
                borderRadius: '9999px',
                ml: 4
              }}>
              <ContentPasteOffIcon sx={{ fontSize: '18px' }} />{' '}
              <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>Cancelled</Typography>
            </Box>
          </Box>
        ))}
      </Box>

      <Box
        sx={{
          mt: 5,
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
          gap: 2,
          width: '100%'
        }}>
        <CustomeButton
          sx={{
            width: '150px',
            background: 'lightgrey',
            color: '#000'
          }}
          variant="contained"
          onClick={() => setSteps(1)}>
          Back
        </CustomeButton>
        <CustomeButton
          sx={{ width: '150px' }}
          variant="contained"
          color="primary"
          onClick={() => setSteps(3)}>
          Next
        </CustomeButton>
      </Box>
    </Box>
  );
}
