import type { StateCreator } from 'zustand';

export type UserType = {
  token: string;
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  is_verified: number;
  created_at: string;
  updated_at: string;
  restaurant_id: string;
  role: string;
};

export interface UserSlice {
  users: UserType[];
  loggedInUser: UserType | null;
  addUser: (user: UserType) => void;
  removeUser: (id: string) => void;
  updateUser: (user: UserType) => void;
  setLoggedInUser: (user: UserType) => void;
}

export const userSlice: StateCreator<UserSlice> = (set) => ({
  users: [],
  loggedInUser: null,
  addUser: (user: UserType) =>
    set((state: UserSlice) => ({
      users: [user, ...state.users]
    })),
  removeUser: (id: string | number) =>
    set((state: UserSlice) => ({
      users: state.users.filter((user) => user.id !== id)
    })),
  setLoggedInUser: (user: UserType) =>
    set(() => ({
      loggedInUser: user
    })),
  updateUser: (user: UserType) =>
    set((state: UserSlice) => ({
      users: state.users.map((u) => {
        if (u.id === user.id) {
          return {
            ...u,
            ...user
          };
        } else {
          return u;
        }
      })
    }))
});
