import { Typography } from '@mui/material';
import React from 'react';
interface IErrorMessageProps {
  validator: any;
  label: string;
  value: string | number | undefined;
  validationString?: string;
}

export default function ErrorMessage(props: IErrorMessageProps) {
  const { validator, label, value, validationString } = props;
  return (
    <Typography variant="body2" color="error" style={{ textAlign: 'left' }}>
      {validator.current.message(label, value, validationString ? validationString : 'required')}
    </Typography>
  );
}
