import axiosInstance from '@services/axios';
import { LoginDataType, SignupDataType } from 'types/services';

export const register = (data: Omit<SignupDataType, 'confirm_password'>) => {
  return axiosInstance.post(`/auth/register-merchant`, data);
};

export const login = (data: LoginDataType) => {
  return axiosInstance.post(`/auth/login-merchant`, data);
};
