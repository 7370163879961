import CustomButton from '@components/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
  Box,
  Divider,
  FormControl,
  Grid2,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import React from 'react';

import CustomSwitcher from './CustomSwitch';

export default function DateSelection(props: {
  showAddSpecialHoursModal: boolean;
  setshowAddSpecialHoursModal: any;
  setStep: any;
  setSelectedDate: any;
  selectedDate: any;
}) {
  const {
    showAddSpecialHoursModal,
    setshowAddSpecialHoursModal,
    setStep,
    setSelectedDate,
    selectedDate
  } = props;

  const formatDate = (date: Date) => {
    return new Intl.DateTimeFormat('en-US', {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    }).format(date);
  };

  const currentYear = new Date().getFullYear();

  const specialDays = [
    {
      label: 'Tomorrow',
      date: new Date(new Date().setDate(new Date().getDate() + 1))
    },
    { label: 'Veterans Day', date: new Date(`${currentYear}-11-11`) },
    {
      label: 'Day Before Thanksgiving',
      date: new Date(`${currentYear}-11-27`)
    },
    { label: 'Thanksgiving', date: new Date(`${currentYear}-11-28`) },
    { label: 'Christmas Eve', date: new Date(`${currentYear}-12-24`) },
    { label: 'Christmas', date: new Date(`${currentYear}-12-25`) }
  ];

  return (
    <Box
      sx={{
        width: '100%',
        mt: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 4
      }}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          mt: 2
        }}>
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: 'bold'
          }}>
          Dates
        </Typography>
        <FormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedDate?.date}
            onChange={(e) => {
              setSelectedDate({ ...selectedDate, date: e.target.value });
            }}>
            <MenuItem
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                py: 2
              }}
              value={'Custom Date Range'}>
              Custom Date Range
            </MenuItem>
            {specialDays?.map((day, i) => {
              return (
                <MenuItem
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    py: 2
                  }}
                  value={formatDate(day.date)}
                  key={i}>
                  {day.label}
                  <span style={{ marginLeft: '10px' }}>- {formatDate(day.date)}</span>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: 'bold',
            mt: 5
          }}>
          Will you be open ro closed?
        </Typography>
        <CustomSwitcher
          checked={selectedDate?.status}
          handleCheck={() => {
            setSelectedDate({ ...selectedDate, status: !selectedDate?.status });
          }}
        />
      </Box>

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
          gap: 2,
          mt: 5
        }}>
        <CustomButton
          sx={{ px: 3 }}
          variant="contained"
          color="primary"
          onClick={() => {
            setStep('Confirm Hours');
          }}>
          Next
        </CustomButton>
      </Box>
    </Box>
  );
}
