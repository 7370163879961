import { Order } from 'types/services';
import type { StateCreator } from 'zustand';

type snackBarDataType = {
  message: string;
  open: boolean;
  type: 'success' | 'error' | 'info' | 'warning';
};

type NotificationDataType = {
  newOrders: Order[];
  readyOrders: Order[];
  open: boolean;
  isNew: boolean;
  isReady: boolean;
};

type NavItem = {
  title: string;
};

export type OrderStatusType = {
  id: string;
  name: string;
};

export interface CommonSlice {
  componentHeading: string;
  setComponentHeading: (text: string) => void;
  snackBarData: snackBarDataType;
  setSnackBarData: (data: snackBarDataType) => void;
  selectedNavItem: NavItem;
  setSelectedNavItem: (item: NavItem) => void;
  notificationData: NotificationDataType;
  setNotificationData: (data: NotificationDataType) => void;
  orderStatuses: OrderStatusType[];
  setOrderStatuses: (data: OrderStatusType[]) => void;
}

export const commonSlice: StateCreator<CommonSlice> = (set) => ({
  componentHeading: '',
  selectedNavItem: {
    title: 'Active Orders'
  },
  orderStatuses: [],
  setOrderStatuses: (orderStatuses: OrderStatusType[]) => set(() => ({ orderStatuses })),
  setSelectedNavItem: (selectedNavItem: NavItem) =>
    set(() => ({
      selectedNavItem
    })),
  setComponentHeading: (text: string) =>
    set(() => ({
      componentHeading: text
    })),
  snackBarData: {
    open: false,
    message: '',
    type: 'success'
  },
  setSnackBarData: (data: snackBarDataType) =>
    set(() => ({
      snackBarData: data
    })),
  notificationData: {
    open: false,
    isNew: false,
    isReady: false,
    newOrders: [],
    readyOrders: []
  },
  setNotificationData: (data: NotificationDataType) =>
    set(() => ({
      notificationData: data
    }))
});
