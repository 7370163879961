import { Checkbox, FormControlLabel } from '@mui/material';

export default function CheckBox({
  indeterminate,
  checked,
  onClick,
  label
}: {
  indeterminate?: boolean;
  checked: boolean;
  onClick: (status: boolean) => void;
  label: string;
}) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          color="primary"
          indeterminate={indeterminate}
          checked={checked}
          onChange={({ target: { checked } }) => onClick(checked)}
        />
      }
      label={label}
    />
  );
}
