import CustomButton from '@components/Button';
import Header from '@components/Header';
import Loader from '@components/Loader';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import PrintIcon from '@mui/icons-material/Print';
import { Box, Grid2, IconButton, Typography } from '@mui/material';
import { getOrdersHistory } from '@services/order';
import { getRestaurantId } from '@utils/index';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Order, ResponseDataType } from 'types/services';

import IssueWithOrderModal from './IssueWithOrderModal';
import OrderCard from './OrderCard';

export type KitchenStatus = {
  title: string;
  description: string;
  color: string;
};

export type OrderItemType = {
  name: string;
  quantity: number;
  seletedMenuName: string;
  price: number;
};

export type OrderType = {
  id: string;
  orderStatus: string;
  prepTime?: string;
  orderTime: string;
  deliveryDate: string;
  customerName: string;
  restaurantName: string;
  items: OrderItemType[];
};

export type IssueWithOrderActionType = {
  title: string;
  action: () => void;
};

export default function OrderHistory() {
  const navigate = useNavigate();
  const [showPrepTimeModal, setshowPrepTimeModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showIssueWithOrderModal, setshowIssueWithOrderModal] = useState<boolean>(false);

  const [orders, setOrders] = useState<Order[]>([]);

  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);

  const [issueWithOrderActions, setIssueWithOrderActions] = useState<IssueWithOrderActionType[]>([
    {
      title: 'Adjust Prep Time',
      action: () => setshowPrepTimeModal(true)
    },
    {
      title: 'Make Something as out of Stock',
      action: () => navigate('/manage-menu')
    },
    {
      title: 'Support Live Chat',
      action: () => navigate('/help-support')
    },
    {
      title: 'Cancel Order',
      action: () => alert('Cancel Order')
    }
  ]);

  const [totalPrice, setTotalPrice] = useState<number>(0);
  useEffect(() => {
    const price: any = selectedOrder?.items?.reduce((acc, item) => {
      return acc + item.price;
    }, 0);
    setTotalPrice(price);
  }, [selectedOrder]);

  useEffect(() => {
    const restaurantId = getRestaurantId();
    if (restaurantId) {
      setLoading(true);
      getOrdersHistory(restaurantId)
        .then((res) => {
          const orderRes: ResponseDataType<Order[]> = res.data;
          if (orderRes.success) {
            const items = orderRes.items;
            setOrders(items);
            setSelectedOrder(items[0]);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  return (
    <>
      <Grid2 container spacing={0}>
        {loading ? (
          <Loader />
        ) : (
          <>
            <Grid2 size={4} sx={{ height: '100dvh' }}>
              <Header bgColor={'transparent'}>
                <Typography
                  component="div"
                  display={'flex'}
                  justifyContent={'space-between'}
                  alignItems={'center'}>
                  <Typography variant="h6" fontWeight={'bold'}>
                    History
                  </Typography>
                </Typography>
              </Header>
              <Box width={'100%'} sx={{ borderBottom: '4px solid lightgrey' }}></Box>
              {orders.length === 0 ? (
                <Grid2
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 2
                  }}
                  size={12}
                  container>
                  <Typography
                    sx={{
                      fontSize: 16,
                      fontWeight: 'bold',
                      textAlign: 'center',
                      mt: 8
                    }}>
                    Currently no orders
                  </Typography>
                </Grid2>
              ) : (
                <Grid2 container width={'100%'}>
                  <Typography
                    sx={{
                      fontSize: 16,
                      fontWeight: 'bold',
                      textAlign: 'center',
                      mt: 2,
                      ml: 3,
                      mb: 1
                    }}>
                    Orders
                  </Typography>
                  {orders?.map((order, i) => (
                    <OrderCard
                      selectedOrder={selectedOrder}
                      order={order}
                      key={i}
                      onOrderClick={() => setSelectedOrder(order)}
                    />
                  ))}
                </Grid2>
              )}
            </Grid2>
            {selectedOrder?.order_id ? (
              <Grid2 size={8} bgcolor={'#fff'} sx={{ height: '100dvh' }}>
                <Box
                  sx={{
                    display: 'flex',
                    px: 2,
                    py: '12px',
                    height: '65px',
                    justifyContent: 'space-between'
                  }}>
                  <Box display={'flex'} flexDirection={'column'}>
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontWeight: 'bold'
                      }}>
                      {selectedOrder?.restaurant_info?.name || ''}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 12,
                        fontWeight: 'bold',
                        color: '#bebebe'
                      }}>
                      #{selectedOrder?.order_id?.slice(0, 10)} . {selectedOrder?.items?.length}{' '}
                      items
                    </Typography>
                  </Box>
                  <Box display={'flex'} gap={1}>
                    <IconButton
                      aria-label="delete"
                      size="small"
                      sx={{
                        py: '6px',
                        borderRadius: '4px',
                        border: '2px solid lightgrey',
                        bgcolor: '#fff',
                        width: '40px'
                      }}>
                      <PrintIcon fontSize="inherit" />
                    </IconButton>
                    <CustomButton
                      variant="outlined"
                      sx={{
                        py: '6px',
                        borderRadius: '4px',
                        border: '2px solid lightgrey',
                        bgcolor: '#fff'
                      }}
                      onClick={() => {
                        setshowIssueWithOrderModal(true);
                      }}>
                      <Typography
                        sx={{
                          fontSize: 12,
                          fontWeight: 'bold',
                          color: '#000'
                        }}>
                        Issue with Order
                      </Typography>
                    </CustomButton>
                  </Box>
                </Box>
                <Box width={'100%'} sx={{ borderBottom: '2px solid lightgrey' }}></Box>
                <Box>
                  <Box
                    sx={{
                      px: 2
                    }}
                    width={'100%'}>
                    <Typography
                      component="div"
                      display={'flex'}
                      flexDirection={'column'}
                      justifyContent={'start'}
                      alignItems={'start'}
                      sx={{
                        borderBottom: '1px solid lightgrey',
                        width: '100%',
                        py: 2
                      }}>
                      <Box
                        sx={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          mt: 3
                        }}>
                        <Typography component={'div'}>
                          <Typography
                            sx={{
                              fontSize: 16,
                              fontWeight: 'bold',
                              textAlign: 'start',
                              color: '#a8a8a8'
                            }}>
                            Assigned Driver
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: 16,
                              fontWeight: 'bold',
                              textAlign: 'start'
                            }}>
                            Driver Name
                          </Typography>
                        </Typography>
                        <Box display={'flex'} gap={1}>
                          <IconButton
                            aria-label="delete"
                            size="small"
                            sx={{
                              py: '8px',
                              borderRadius: '4px',
                              border: '2px solid lightgrey',
                              bgcolor: '#fff',
                              width: '40px'
                            }}>
                            <PhoneInTalkIcon fontSize="inherit" />
                          </IconButton>
                          <CustomButton
                            variant="outlined"
                            sx={{
                              py: '8px',
                              borderRadius: '4px',
                              border: '2px solid lightgrey',
                              bgcolor: '#fff'
                            }}>
                            <Typography
                              sx={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                color: '#000'
                              }}>
                              Driver Feedback
                            </Typography>
                          </CustomButton>
                        </Box>
                      </Box>
                    </Typography>
                    <Typography
                      component="div"
                      sx={{
                        width: '100%'
                      }}>
                      {selectedOrder?.items?.map((item, i) => {
                        return (
                          <Typography
                            key={i}
                            sx={{
                              fontSize: 12,
                              fontWeight: 'bold',
                              color: '#000',
                              width: '100%',
                              borderBottom: '1px solid lightgrey',
                              py: 1,
                              px: 1,
                              bgcolor: '#fff'
                            }}>
                            <Typography
                              sx={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                color: 'grey',
                                width: '100%'
                              }}>
                              {item.name}
                            </Typography>
                            <Box
                              sx={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between',
                                mt: 2
                              }}>
                              <Typography
                                sx={{
                                  fontSize: 14,
                                  fontWeight: 'bold',
                                  color: '#000'
                                }}
                                key={i}>
                                {1} X
                                <span
                                  style={{
                                    color: 'grey'
                                  }}>
                                  --
                                </span>{' '}
                                {item.name}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: 14,
                                  fontWeight: 'normal',
                                  color: '#000'
                                }}
                                key={i}>
                                ${item.price}
                              </Typography>
                            </Box>
                          </Typography>
                        );
                      })}
                    </Typography>
                  </Box>
                </Box>
                <Typography
                  component="div"
                  display={'flex'}
                  justifyContent={'space-between'}
                  alignItems={'center'}>
                  <Grid2
                    container
                    width={'100%'}
                    spacing={2}
                    px={3}
                    py={2}
                    sx={{ borderTop: '1px solid lightgrey' }}>
                    <Grid2
                      size={12}
                      bgcolor={'#fff'}
                      display={'flex'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      py={2}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          width: '60%'
                        }}>
                        <Typography
                          sx={{
                            fontSize: 12,
                            fontWeight: 'bold',
                            color: '#000'
                          }}>
                          {selectedOrder?.items?.length} total items
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: 12,
                            fontWeight: 'bold',
                            color: '#000'
                          }}>
                          Subtotal:
                        </Typography>
                      </Box>
                      <Typography
                        sx={{
                          fontSize: 12,
                          fontWeight: 'bold',
                          color: '#000'
                        }}>
                        ${totalPrice}
                      </Typography>
                    </Grid2>
                  </Grid2>
                </Typography>
              </Grid2>
            ) : (
              <Grid2 size={8} bgcolor={'#fff'}></Grid2>
            )}
          </>
        )}
      </Grid2>

      {/* Issue with order modal */}
      <IssueWithOrderModal
        showIssueWithOrderModal={showIssueWithOrderModal}
        setShowIssueWithOrderModal={setshowIssueWithOrderModal}
        issueWithOrderActions={issueWithOrderActions}
      />
    </>
  );
}
