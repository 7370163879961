import ErrorMessage from '@components/ErrorMessage';
import { Autocomplete, TextField, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';

export interface DropdownOptionType {
  id: string | number;
  label: string;
}

export default function Dropdown(props: {
  options: DropdownOptionType[];
  onChange: any;
  label: string;
  value: string;
  inputName: string;
  validator?: any;
  required?: boolean;
  optionText?: string;
  size?: 'small' | 'medium';
  disabled?: boolean;
}) {
  const {
    options,
    size,
    onChange,
    label,
    value,
    inputName,
    required,
    validator,
    optionText,
    disabled
  } = props;
  const [val, setVal] = useState<string>('');

  useEffect(() => {
    if (value) {
      setVal(options.find((o) => o.id === value)?.label || '');
    }
  }, [value, options]);

  const isError = validator?.current?.message(inputName, value, 'required');

  return (
    <>
      <Autocomplete
        noOptionsText="No options available"
        options={options}
        value={options.find((option) => option.id === value)}
        onInputChange={(event, newInputValue) => {
          setVal(newInputValue);
        }}
        disableClearable
        onChange={(event, newValue: any) => {
          setVal(newValue?.value);
          onChange({ target: { name: inputName, value: newValue.id } });
        }}
        fullWidth
        inputValue={val}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <div style={{ position: 'relative' }}>
            <TextField
              {...params}
              variant="outlined"
              error={required && isError}
              sx={{
                mt: 1,
                '& .MuiFilledInput-root': {
                  borderBottom: '1px solid #000'
                }
              }}
              type="text"
              name={inputName}
              label={label}
              fullWidth
              size={size || 'medium'}
              slotProps={{
                inputLabel: {
                  shrink: true
                },
                input: { ...params.InputProps, disableUnderline: true }
              }}
              placeholder={optionText || 'Choose...'}
              disabled={disabled}
            />
            {required && (
              <span style={{ position: 'absolute', right: '5px', top: '10px', color: 'red' }}>
                *
              </span>
            )}
          </div>
        )}
      />
      {validator && <ErrorMessage label={inputName} value={value} validator={validator} />}
    </>
  );
}
