import type { ButtonProps } from '@mui/material';
import { Button } from '@mui/material';

interface propsType extends ButtonProps {
  default?: boolean;
}

export default function CustomButton(props: propsType) {
  return (
    <Button
      {...props}
      {...(props.default
        ? {
            sx: {
              ...props.sx,
              background: '#e2e6e5',
              color: '#000'
            }
          }
        : {})}
    />
  );
}
