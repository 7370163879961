import { Box, Slider, Typography } from "@mui/material";
import React, { useState } from "react";

export default function VerticalNumberRange(props: {
  minValue: number;
  maxValue: number;
  value: number;
  setValue: any;
  rangeText: string;
  rightCationText?: string;
  leftCationText?: string;
}) {
  const {
    minValue,
    maxValue,
    value,
    setValue,
    rangeText,
    leftCationText,
    rightCationText,
  } = props;
  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number);
  };

  return (
    <Box
      sx={{
        height: 200,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        width: "100%",
      }}
    >
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        {value > minValue && (
          <Typography
            sx={{ fontSize: 24, fontWeight: "bold", color: "darkgray", py: 2 }}
          >
            {value - 1} {rangeText}
          </Typography>
        )}

        <Typography
          sx={{
            fontWeight: "bold",
            borderBlock: "2px solid #bebebe",
            width: "100%",
            py: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: 2,
          }}
        >
          <Typography
            sx={{
              fontSize: 20,
              color: "green",
              fontWeight: "bold",
            }}
          >
            {rightCationText}
          </Typography>
          <Typography
            sx={{
              fontSize: 28,
              position: "absolute",
              left: 0,
              right: 0,
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            {value} {rangeText}
          </Typography>
          <Typography
            sx={{ fontSize: 20, color: "darkgray", fontWeight: "bold" }}
          >
            {leftCationText}
          </Typography>
        </Typography>

        {value < maxValue && (
          <Typography
            sx={{ fontSize: 24, fontWeight: "bold", color: "darkgray", py: 2 }}
          >
            {value + 1} {rangeText}
          </Typography>
        )}
      </Box>
      {/* Invisible Slider */}
      <Slider
        orientation="vertical"
        value={value}
        min={minValue}
        max={maxValue}
        onChange={handleChange}
        aria-labelledby="vertical-slider"
        sx={{
          height: 150,
          position: "absolute",
          opacity: 0,
          "& .MuiSlider-thumb": {
            visibility: "visible",
          },
        }}
      />
    </Box>
  );
}
