import Modal from '@components/Modal';
import CallIcon from '@mui/icons-material/Call';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Order } from 'types/services';

import ContactTheCustomer from './ContactTheCustomer';
import HowLongOutOfStock from './HowLongOutOfStock';
import OrderHasBeenCancelled from './OrderHasBeenCancelled';
import ReplacementItems from './ReplacementItems';
import WhatOutOfStock from './WhatOutOfStock';

export default function MakeOutOfStockModal(props: {
  showMakeSomethingOutOfStockModal: boolean;
  setShowMakeSomethingOutOfStockModal: any;
  order: Order;
}) {
  const { showMakeSomethingOutOfStockModal, setShowMakeSomethingOutOfStockModal, order } = props;

  const [steps, setSteps] = useState<number>(1);

  return (
    <React.Fragment>
      <Modal
        title={<></>}
        open={showMakeSomethingOutOfStockModal}
        closeModal={() => {
          setShowMakeSomethingOutOfStockModal(false);
        }}
        hideCloseButton
        fullScreen={true}
        content={
          <Box sx={{ position: 'absolute', inset: 0, bgcolor: '#fff', display: 'flex' }}>
            <IconButton
              aria-label="close"
              onClick={() => setShowMakeSomethingOutOfStockModal(false)}
              sx={{
                bgcolor: '#fff',
                boxShadow: '0px 3px 10px lightgrey',
                position: 'absolute',
                right: 10,
                top: 10,
                color: '#000'
              }}>
              <CloseIcon />
            </IconButton>
            <Box sx={{ flex: 1, height: '100vh', bgcolor: '#EDF2F3', p: 2, position: 'relative' }}>
              <Box sx={{ bgcolor: '#fff', borderRadius: '4px' }}>
                <Typography sx={{ fontSize: 18, fontWeight: 'bold', px: 2, py: 2 }}>
                  Selected Item
                </Typography>
                <Divider sx={{ width: '100%' }} />
                <Box sx={{ px: 2, py: 2 }}>
                  <Typography sx={{ fontSize: 16, fontWeight: 'bold', color: 'grey' }}>
                    Frozen Yogurt
                  </Typography>
                  <Typography
                    sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', py: 1 }}>
                    <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>
                      1 x Tiramisu Gelato
                    </Typography>
                    <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>$17.5</Typography>
                  </Typography>
                  <Typography
                    sx={{ display: 'flex', flexDirection: 'column', gap: 2, px: 3, pb: 5 }}>
                    <Typography
                      sx={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        color: 'grey'
                      }}>
                      Choice of size
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 16,
                        fontWeight: 'bold'
                      }}>
                      Regular (16oz Cup)
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        color: 'grey'
                      }}>
                      Toppings
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 16,
                        fontWeight: 'bold'
                      }}>
                      Andes Mints (+$0.75)
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 16,
                        fontWeight: 'bold'
                      }}>
                      Baklawa (1) (+$07.50)
                    </Typography>
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  width: '100%',
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  bottom: 0,
                  height: '100px',
                  bgcolor: '#fff',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  px: 2
                }}>
                <Typography>
                  <Typography
                    sx={{
                      fontSize: 16,
                      fontWeight: 'bold',
                      color: 'grey'
                    }}>
                    Customer
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 16,
                      fontWeight: 'bold',
                      color: '#000'
                    }}>
                    Greg M.
                  </Typography>
                </Typography>
                <IconButton
                  sx={{
                    color: '#000',
                    bgcolor: 'lightgrey'
                  }}
                  aria-label="add to shopping cart">
                  <CallIcon />
                </IconButton>
              </Box>
            </Box>
            <Box sx={{ flex: 3, p: 3 }}>
              {/* What is out of stock */}
              {steps === 1 && (
                <WhatOutOfStock
                  setSteps={(s: number) =>
                    s === -1 ? setShowMakeSomethingOutOfStockModal(false) : setSteps(s)
                  }
                />
              )}
              {/* How long out of stock */}
              {steps === 2 && <HowLongOutOfStock setSteps={setSteps} />}
              {/* Replacement Items */}
              {steps === 3 && <ReplacementItems setSteps={setSteps} />}
              {/* Contact the Customer */}
              {steps === 4 && <ContactTheCustomer setSteps={setSteps} />}
              {/* Order has been cancelled */}
              {steps === 5 && <OrderHasBeenCancelled setSteps={setSteps} />}
            </Box>
          </Box>
        }
      />
    </React.Fragment>
  );
}
