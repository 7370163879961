import { Chip, Grid2, Typography } from '@mui/material';
import moment from 'moment';
import { Order } from 'types/services';

export default function OrderCard(props: {
  selectedOrder: Order | null;
  order: Order;
  onOrderClick: () => void;
}) {
  const { selectedOrder, order, onOrderClick } = props;
  const orderTime = moment(order.created_at).fromNow();
  return (
    <Grid2
      sx={{
        borderLeft: `5px solid ${selectedOrder?.order_id === order?.order_id ? '#5f73c7' : '#fff'}`,
        px: 1,
        py: 2,
        borderBottom: `1px solid #edf2f3`,
        cursor: 'pointer'
      }}
      onClick={() => onOrderClick()}
      container
      width={'100%'}
      spacing={2}
      justifyContent={'space-between'}
      alignItems={'center'}
      bgcolor={'#fff'}>
      <Grid2 size={8} display={'flex'} flexDirection={'column'}>
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 'bold'
          }}>
          {order?.restaurant_info?.name || ''}
        </Typography>
        <Typography
          sx={{
            fontSize: 12,
            fontWeight: 'bold',
            color: '#bebebe'
          }}>
          #{order?.order_id?.slice(0, 10)} . {order?.items?.length} items
        </Typography>
        {order.status === 'PREPARING' && (
          <Chip
            label="Not Prepared"
            color="warning"
            sx={{ width: 'max-content', height: '20px', background: 'yellow', color: '#000' }}
          />
        )}

        {order.status === 'OUT FOR DELIVERY' && (
          <Chip
            label="Delivering"
            color="success"
            sx={{ width: 'max-content', height: '20px', background: 'green', color: '#fff' }}
          />
        )}
      </Grid2>
      {order?.status === 'NEW' && (
        <Grid2 size={4}>
          <Typography
            sx={{
              fontSize: 12,
              fontWeight: 'bold',
              textAlign: 'end'
            }}>
            {order?.status?.toUpperCase()}
          </Typography>
          <Typography
            sx={{
              fontSize: 12,
              fontWeight: 'bold',
              color: '#bebebe',
              textAlign: 'end'
            }}>
            {orderTime}
          </Typography>
        </Grid2>
      )}
      {order?.status === 'READY FOR PICKUP' && (
        <Grid2 size={4}>
          <Typography
            sx={{
              fontSize: 12,
              fontWeight: 'bold',
              textAlign: 'end',
              color: 'orange'
            }}>
            Driver Arriving
          </Typography>
          <Typography
            sx={{
              fontSize: 12,
              fontWeight: 'bold',
              color: '#bebebe',
              textAlign: 'end'
            }}>
            etc {'5'} min
          </Typography>
        </Grid2>
      )}
      {order?.status === 'PREPARING' && (
        <Grid2 size={4}>
          <Typography
            sx={{
              fontSize: 12,
              fontWeight: 'bold',
              color: '#bebebe',
              textAlign: 'end'
            }}>
            Ready in {order?.prep_time} min
          </Typography>
        </Grid2>
      )}
    </Grid2>
  );
}
