import { colors, Grid2, Typography } from '@mui/material';
import moment from 'moment';
import { Order } from 'types/services';

const statusBGColor: any = {
  PENDING: { bg: '#FFC107', color: '#000' },
  PREPARING: { bg: '#4CAF50', color: '#fff' },
  'OUT FOR DELIVERY': { bg: '#027c01', color: '#fff' },
  CANCELED: { bg: '#FF0000', color: '#fff' },
  'READY FOR PICKUP': { bg: '#fffc05', color: '#000' },
  DELIVERED: { bg: '#027c01', color: '#fff' },
  COMPLETED: { bg: '#027c01', color: '#fff' }
};

export default function OrderCard(props: {
  selectedOrder: Order | null;
  order: Order;
  onOrderClick: () => void;
}) {
  const { selectedOrder, order, onOrderClick } = props;

  return (
    <Grid2
      sx={{
        borderLeft: `5px solid ${selectedOrder?.order_id === order?.order_id ? '#5f73c7' : '#fff'}`,
        px: 1,
        py: 2,
        borderBottom: `1px solid #edf2f3`,
        cursor: 'pointer'
      }}
      onClick={() => onOrderClick()}
      container
      width={'100%'}
      spacing={2}
      justifyContent={'space-between'}
      alignItems={'center'}
      bgcolor={'#fff'}>
      <Grid2 size={8} display={'flex'} flexDirection={'column'}>
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 'bold'
          }}>
          {order?.restaurant_info?.name || ''}
        </Typography>
        <Typography
          sx={{
            fontSize: 12,
            fontWeight: 'bold',
            color: '#bebebe'
          }}>
          #{order?.order_id?.slice(0, 10)} . {order?.items?.length} items
        </Typography>
      </Grid2>
      <Grid2 size={4}>
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 'bold',
            color: '#bebebe',
            textAlign: 'end'
          }}>
          {moment(order.created_at).format('MMM D [@] h:mm A')}
        </Typography>
        <Typography
          sx={{
            float: 'right',
            mt: 1,
            width: 'max-content',
            fontSize: 12,
            fontWeight: 'bold',
            padding: '2px 8px',
            borderRadius: '5px',
            background: statusBGColor[order.status].bg,
            color: statusBGColor[order.status].color
          }}>
          {order.status}
        </Typography>
      </Grid2>
    </Grid2>
  );
}
