import { CssBaseline } from "@mui/material";
import {
    createTheme,
    StyledEngineProvider,
    ThemeOptions,
    ThemeProvider as MUIThemeProvider,
} from "@mui/material/styles";

// Define your primary and secondary colors
const primaryColor = {
    900: "#3449a5",
    800: "#3d54c1",
    700: "#475fdd",
    600: "#586bf1",
    500: "#6977f5",
};

const secondaryColor = {
    900: "#a5231e",
    800: "#b52a23",
    700: "#c53028",
    600: "#d6362d",
    500: "#e63d33",
};

// Define the theme options
const themeOptions: ThemeOptions = {
    palette: {
        primary: {
            main: primaryColor[500],
            light: primaryColor[600],
            dark: primaryColor[700],
            contrastText: "#ffffff",
        },
        secondary: {
            main: secondaryColor[500],
            light: secondaryColor[600],
            dark: secondaryColor[700],
            contrastText: "#ffffff",
        },
    },
    typography: {
        fontFamily: "Poppins",
    },
};

type Props = {
    children: React.ReactNode;
};

export function ThemeProvider({ children }: Props) {
    const theme = createTheme(themeOptions);

    return (
        <StyledEngineProvider injectFirst>
            <MUIThemeProvider theme={theme}>
                <CssBaseline />
                {children}
            </MUIThemeProvider>
        </StyledEngineProvider>
    );
}
export default ThemeProvider;
