import ErrorMessage from '@components/ErrorMessage';
import { TextField } from '@mui/material';
import { ComponentPropsWithoutRef } from 'react';

interface CustomTextfieldProps extends ComponentPropsWithoutRef<typeof TextField> {
  isRequired?: boolean;
  validator?: any;
  validationString?: string;
  value: number | string;
  name: string;
}

export default function CustomTextfield(props: CustomTextfieldProps) {
  const { validator, validationString, name, value, isRequired } = props;
  const isError = validator?.current?.message(
    name,
    value,
    validationString ? validationString : 'required'
  );
  return (
    <>
      <div style={{ position: 'relative' }}>
        <TextField
          {...props}
          sx={{
            ...props.sx,
            mb: 1,
            mt: 1,
            padding: 0
          }}
          variant={props.variant || 'outlined'}
        />

        {isRequired && (
          <span
            style={{
              position: 'absolute',
              right: '5px',
              top: '10px',
              color: 'red'
            }}>
            *
          </span>
        )}
      </div>
      {isRequired && isError && (
        <ErrorMessage
          validator={validator}
          value={value}
          label={name}
          validationString={validationString || ''}
        />
      )}
    </>
  );
}
