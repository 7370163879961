import CustomButton from '@components/Button';
import Header from '@components/Header';
import { Assignment, Bolt, KeyboardArrowDown, Notifications, Print } from '@mui/icons-material';
import { Menu, MenuItem, Paper, Switch, Typography } from '@mui/material';
import { updateRestaurant } from '@services/restaurant';
import { useRestaurantStore } from '@store/index';
import { getRestaurantId } from '@utils/index';
import { useEffect, useState } from 'react';

export default function Settings() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [state, setState] = useState<{
    auto_confirm_order: boolean;
    new_order_alter_volume: string;
    dasher_arriving_alert: boolean;
  }>({
    auto_confirm_order: false,
    new_order_alter_volume: 'quiet',
    dasher_arriving_alert: false
  });
  const options = [
    { value: 'quiet', label: 'Quiet' },
    { value: 'loud', label: 'Loud' }
  ];
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const restaurantData = useRestaurantStore((state) => state.restaurant);
  const addRestaurantData = useRestaurantStore((state) => state.addRestaurant);

  useEffect(() => {
    if (restaurantData) {
      setState({
        auto_confirm_order: restaurantData?.auto_confirm_order || false,
        new_order_alter_volume: restaurantData.new_order_alter_volume || '',
        dasher_arriving_alert: restaurantData.dasher_arriving_alert || false
      });
    }
  }, [restaurantData]);

  const onUpdateRestaurantSettings = (name: string, value: boolean | string) => {
    const restaurantId = getRestaurantId();
    if (restaurantId) {
      setLoading(true);
      const data = {
        id: restaurantId,
        [name]: value
      };
      updateRestaurant(data)
        .then((res) => {
          if (res.data.success) {
            addRestaurantData({ ...restaurantData, ...data, status: '' });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      <Header>
        {' '}
        <Typography variant="h6" fontWeight={'bold'}>
          Settings
        </Typography>
      </Header>
      <Typography
        component="div"
        sx={{
          p: 3,
          '.paper': {
            borderRadius: '10px',
            mt: 1,
            '& > div:not(:last-child)': {
              borderBottom: '2px solid #eee'
            },
            '& > div': {
              p: 2,
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              '& > div': {
                display: 'flex',
                alignItems: 'center',
                fontWeight: 'bold'
              },
              svg: {
                mr: 1
              }
            }
          }
        }}>
        <Typography variant="h5">Orders</Typography>
        <Paper className="paper">
          <Typography component="div">
            <Typography component="div">
              <Bolt /> Auto-confirm new orders
            </Typography>
            <Typography component="div">
              <Switch
                color="secondary"
                checked={state.auto_confirm_order}
                onChange={({ target: { checked } }) => {
                  setState({ ...state, auto_confirm_order: checked });
                  onUpdateRestaurantSettings('auto_confirm_order', checked);
                }}
              />
            </Typography>
          </Typography>
          <Typography component="div">
            <Typography component="div">
              <Notifications /> New order alter volume
            </Typography>
            <Typography component="div">
              <div>
                <Typography component="div" display="flex" sx={{ cursor: 'pointer' }}>
                  <Typography color="secondary">{state.new_order_alter_volume}</Typography>
                  <Typography
                    component="div"
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? 'long-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}>
                    <KeyboardArrowDown />
                  </Typography>
                </Typography>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    'aria-labelledby': 'long-button'
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  slotProps={{
                    paper: {
                      style: {
                        width: '20ch'
                      }
                    }
                  }}>
                  {options.map((option) => (
                    <MenuItem
                      key={option.value}
                      selected={option.value === state.new_order_alter_volume}
                      onClick={() => {
                        setState({ ...state, new_order_alter_volume: option.value });
                        onUpdateRestaurantSettings('new_order_alter_volume', option.value);
                        handleClose();
                      }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            </Typography>
          </Typography>
          <Typography component="div">
            <Typography component="div">
              <Assignment /> Create test order
            </Typography>
          </Typography>
          <Typography component="div" borderBottom="2px solid #eee">
            <Typography component="div">
              <Bolt /> Alter when dasher is arriving
            </Typography>
            <Typography component="div">
              <Switch
                color="secondary"
                checked={state.dasher_arriving_alert}
                onChange={({ target: { checked } }) => {
                  setState({ ...state, dasher_arriving_alert: checked });
                  onUpdateRestaurantSettings('dasher_arriving_alert', checked);
                }}
              />
            </Typography>
          </Typography>
        </Paper>
        <br />
        <Typography variant="h5">Printer</Typography>
        <Paper className="paper">
          <Typography component="div">
            <Typography component="div">
              <Typography component="div">
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    display: 'flex'
                  }}
                  component="div">
                  <Print sx={{ mr: 1 }} /> Printer
                </Typography>
                <Typography color="secondary" sx={{ fontWeight: 'bold', ml: 4 }}>
                  No printer setup
                </Typography>
              </Typography>
            </Typography>
            <Typography component="div">
              <CustomButton color="secondary" sx={{ textTransform: 'none' }}>
                Setup printer
              </CustomButton>
            </Typography>
          </Typography>
        </Paper>
      </Typography>
    </>
  );
}
