import CustomButton from '@components/Button';
import CustomTextfield from '@components/Textfield';
import { CircularProgress, Divider, Grid2, Typography, useTheme } from '@mui/material';
import { register } from '@services/auth';
import { useCommonStore } from '@store/index';
import { getValidations } from '@utils/index';
import { ChangeEvent, useReducer, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Validator from 'simple-react-validator';
import { RegisterAPIResponseType, ResponseDataType, SignupDataType } from 'types/services';

export default function Signup() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [data, setData] = useState<SignupDataType>({
    first_name: '',
    last_name: '',
    password: '',
    confirm_password: '',
    email: '',
    name: '',
    about: '',
    lat: '',
    lng: '',
    street_address: '',
    city: '',
    province: '',
    country: '',
    zip_code: '',
    contact_name: '',
    contact_email: '',
    contact_phone: '',
    website: '',
    delivery_fee: '',
    delivery_time: '',
    image: ''
  });
  const { messages } = getValidations();
  const validator = useRef(
    new Validator({
      messages: {
        ...messages,
        in: 'Password does not match'
      }
    })
  );

  const setSnackBarData = useCommonStore((state) => state.setSnackBarData);

  const handleChange = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
    setData((state) => ({ ...state, [name]: value }));
  };

  const onSubmit = () => {
    if (validator.current.allValid()) {
      setLoading(true);
      register(data)
        .then((res) => {
          const apiRes = res.data;
          if (apiRes.data?.success) {
            setSnackBarData({
              open: true,
              type: 'success',
              message: 'Restaurant registered successfully'
            });
            navigate('/login');
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      validator.current.showMessages();
      forceUpdate();
    }
  };

  validator.current.purgeFields();

  return (
    <Grid2 container spacing={1}>
      <Grid2 size={{ xs: 12 }}>
        <Typography sx={{ fontSize: '20px' }}>Login Information</Typography>
      </Grid2>

      <Grid2 size={{ xs: 12, sm: 6 }}>
        <CustomTextfield
          fullWidth
          color="secondary"
          name="first_name"
          label="First name"
          onChange={handleChange}
          value={data.first_name}
          isRequired
          validator={validator}
        />
      </Grid2>
      <Grid2 size={{ xs: 12, sm: 6 }}>
        <CustomTextfield
          fullWidth
          onChange={handleChange}
          color="secondary"
          name="last_name"
          label="Last name"
          value={data.last_name}
          isRequired
          validator={validator}
        />
      </Grid2>
      <Grid2 size={{ xs: 12, sm: 6 }}>
        <CustomTextfield
          fullWidth
          onChange={handleChange}
          color="secondary"
          name="email"
          label="Email Address"
          value={data.email}
          isRequired
          validator={validator}
          validationString="required|email"
        />
      </Grid2>
      <Grid2 size={{ xs: 12, sm: 6 }}>
        <CustomTextfield
          onChange={handleChange}
          fullWidth
          color="secondary"
          name="password"
          type="password"
          label="Password"
          value={data.password}
          isRequired
          validator={validator}
        />
      </Grid2>
      <Grid2 size={{ xs: 12, sm: 6 }}>
        <CustomTextfield
          onChange={handleChange}
          fullWidth
          color="secondary"
          name="confirm_password"
          type="password"
          label="Confirm Password"
          value={data.confirm_password}
          isRequired
          validator={validator}
          validationString={`required|in:${data.password}`}
        />
      </Grid2>
      <Grid2 size={{ xs: 12 }}>
        <Typography sx={{ fontSize: '20px' }}>Restaurant Information</Typography>
      </Grid2>
      <Grid2 size={{ xs: 12, sm: 6 }}>
        <CustomTextfield
          fullWidth
          onChange={handleChange}
          color="secondary"
          name="name"
          label="Restaurant Name"
          value={data.name}
          isRequired
          validator={validator}
        />
      </Grid2>
      <Grid2 size={{ xs: 12, sm: 6 }}>
        <CustomTextfield
          fullWidth
          onChange={handleChange}
          color="secondary"
          name="about"
          label="About"
          value={data.about}
          isRequired
          validator={validator}
        />
      </Grid2>
      <Grid2 size={{ xs: 12, sm: 6 }}>
        <CustomTextfield
          fullWidth
          onChange={handleChange}
          color="secondary"
          name="lat"
          label="Latitude"
          value={data.lat}
          isRequired
          type="number"
          validator={validator}
        />
      </Grid2>
      <Grid2 size={{ xs: 12, sm: 6 }}>
        <CustomTextfield
          fullWidth
          onChange={handleChange}
          color="secondary"
          name="lng"
          label="Longitude"
          value={data.lng}
          isRequired
          type="number"
          validator={validator}
        />
      </Grid2>
      <Grid2 size={{ xs: 12, sm: 6 }}>
        <CustomTextfield
          fullWidth
          onChange={handleChange}
          color="secondary"
          name="street_address"
          label="Street Address"
          value={data.street_address}
          isRequired
          validator={validator}
        />
      </Grid2>
      <Grid2 size={{ xs: 12, sm: 6 }}>
        <CustomTextfield
          fullWidth
          onChange={handleChange}
          color="secondary"
          name="city"
          label="City"
          value={data.city}
          isRequired
          validator={validator}
        />
      </Grid2>
      <Grid2 size={{ xs: 12, sm: 6 }}>
        <CustomTextfield
          fullWidth
          onChange={handleChange}
          color="secondary"
          name="province"
          label="Province"
          value={data.province}
          isRequired
          validator={validator}
        />
      </Grid2>
      <Grid2 size={{ xs: 12, sm: 6 }}>
        <CustomTextfield
          fullWidth
          onChange={handleChange}
          color="secondary"
          name="zip_code"
          label="Zip code"
          value={data.zip_code}
          isRequired
          validator={validator}
        />
      </Grid2>
      <Grid2 size={{ xs: 12, sm: 6 }}>
        <CustomTextfield
          fullWidth
          onChange={handleChange}
          color="secondary"
          name="country"
          label="Country"
          value={data.country}
          isRequired
          validator={validator}
        />
      </Grid2>

      <Grid2 size={{ xs: 12, sm: 6 }}>
        <CustomTextfield
          fullWidth
          onChange={handleChange}
          color="secondary"
          name="contact_name"
          label="Contact Name"
          value={data.contact_name}
          isRequired
          validator={validator}
        />
      </Grid2>
      <Grid2 size={{ xs: 12, sm: 6 }}>
        <CustomTextfield
          fullWidth
          onChange={handleChange}
          color="secondary"
          name="contact_phone"
          label="Contact Phone"
          value={data.contact_phone}
          isRequired
          validator={validator}
        />
      </Grid2>
      <Grid2 size={{ xs: 12, sm: 6 }}>
        <CustomTextfield
          fullWidth
          onChange={handleChange}
          color="secondary"
          name="contact_email"
          label="Contact Email"
          value={data.contact_email}
          isRequired
          validator={validator}
          validationString="required|email"
        />
      </Grid2>
      <Grid2 size={{ xs: 12, sm: 6 }}>
        <CustomTextfield
          fullWidth
          onChange={handleChange}
          color="secondary"
          name="website"
          label="Website"
          value={data.website}
          isRequired
          validator={validator}
        />
      </Grid2>
      <Grid2 size={{ xs: 12, sm: 6 }}>
        <CustomTextfield
          fullWidth
          onChange={handleChange}
          color="secondary"
          name="delivery_fee"
          label="Delivery Fee"
          value={data.delivery_fee}
          isRequired
          validator={validator}
        />
      </Grid2>
      <Grid2 size={{ xs: 12, sm: 6 }}>
        <CustomTextfield
          fullWidth
          onChange={handleChange}
          color="secondary"
          name="delivery_time"
          label="Delivery Time"
          value={data.delivery_time}
          type="time"
          isRequired
          validator={validator}
          slotProps={{
            inputLabel: {
              shrink: true
            }
          }}
        />
      </Grid2>
      <Grid2 size={{ xs: 12 }}>
        <CustomButton
          onClick={onSubmit}
          sx={{ mt: 2 }}
          fullWidth
          variant="contained"
          color="secondary"
          disabled={loading}>
          {loading && <CircularProgress size={20} sx={{ mr: '10px' }} />}
          Register
        </CustomButton>
        <Typography
          component="div"
          color="secondary"
          sx={{
            textAlign: 'center',
            mt: 2,
            cursor: 'pointer',
            a: {
              color: theme.palette.secondary.dark
            }
          }}>
          <Link to="/login">Login here</Link>
        </Typography>
      </Grid2>
    </Grid2>
  );
}
