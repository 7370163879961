import CustomeButton from '@components/Button';
import { Box, Divider, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';

export default function ContactTheCustomer(props: { setSteps: any }) {
  const { setSteps } = props;

  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
      <Typography sx={{ fontSize: '24px', fontWeight: 'bold' }}>Contact the Customer</Typography>
      <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: 'grey', mt: 1 }}>
        Grege M. requested that you contact then when an item is out of stock.
      </Typography>

      <Box sx={{ mt: 5, display: 'flex', alignItems: 'center', gap: 2 }}>
        <Typography
          sx={{
            fontSize: '12px',
            color: 'grey',
            mt: 1,
            border: '2px solid grey',
            borderRadius: '9999px',
            fontWeight: 'bold',
            width: 20,
            height: 20,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          1
        </Typography>
        <Typography sx={{ height: 20, fontSize: '16px', fontWeight: 'bold' }}>
          Call Gred M. at this phone number:
        </Typography>
      </Box>
      <Typography sx={{ fontSize: '16px', color: 'grey', fontWeight: 'bold', ml: 4, mt: 1 }}>
        +1 (555) 555-5555
      </Typography>

      <Box sx={{ mt: 5, display: 'flex', alignItems: 'center', gap: 2 }}>
        <Typography
          sx={{
            fontSize: '12px',
            color: 'grey',
            mt: 1,
            border: '2px solid grey',
            borderRadius: '9999px',
            fontWeight: 'bold',
            width: 20,
            height: 20,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          2
        </Typography>
        <Typography sx={{ height: 20, fontSize: '16px', fontWeight: 'bold' }}>
          Call Gred M. at this phone number:
        </Typography>
      </Box>

      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue={1}
        name="radio-buttons-group"
        sx={{ width: '100%', mt: 1, ml: 3 }}>
        <Typography sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <Radio value={1} />
          <Typography sx={{ fontSize: 16, fontWeight: 'bold', color: 'grey' }}>
            Refund the item
          </Typography>
        </Typography>

        <Typography sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <Radio value={2} />
          <Typography sx={{ fontSize: 16, fontWeight: 'bold', color: 'grey' }}>
            Cancel the order
          </Typography>
        </Typography>

        <Typography sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <Radio value={3} />
          <Typography sx={{ fontSize: 16, fontWeight: 'bold', color: 'grey' }}>
            Replace item
          </Typography>
        </Typography>

        <Typography sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <Radio value={4} />
          <Typography sx={{ fontSize: 16, fontWeight: 'bold', color: 'grey' }}>
            Couldn&apos;t reach customer
          </Typography>
        </Typography>
      </RadioGroup>
      <Box
        sx={{
          mt: 5,
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
          gap: 2,
          width: '100%'
        }}>
        <CustomeButton
          sx={{
            width: '150px',
            background: 'lightgrey',
            color: '#000'
          }}
          variant="contained"
          onClick={() => setSteps(1)}>
          Back
        </CustomeButton>
        <CustomeButton
          sx={{ width: '150px' }}
          variant="contained"
          color="primary"
          onClick={() => setSteps(5)}>
          Next
        </CustomeButton>
      </Box>
    </Box>
  );
}
