import axiosInstance from '@services/axios';

export const getRestaurantOrders = (restaurantId: string) => {
  return axiosInstance.get(`/order/restaurant/${restaurantId}`);
};

export const getOrderById = (id: string) => {
  return axiosInstance.get(`/order/${id}`);
};

export const getOrdersHistory = (restaurantId: string) => {
  return axiosInstance.get(`/order/history/${restaurantId}`);
};

export const updateOrder = (orderData: { id: string; status_id?: string; prep_time?: number }) => {
  return axiosInstance.patch(`/order`, orderData);
};
