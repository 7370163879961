import { getAllStatuses } from '@services/common';
import { OrderStatusType } from '@store/commonSlice';
import { useCommonStore } from '@store/index';
import { getToken } from '@utils/index';
import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { ResponseDataType } from 'types/services';

export default function ProtectedRoute() {
  const token = getToken();
  const setOrderStatuses = useCommonStore((state) => state.setOrderStatuses);
  useEffect(() => {
    if (token) {
      getAllStatuses().then((res) => {
        const data: ResponseDataType<OrderStatusType[]> = res.data;
        if (data.success) {
          setOrderStatuses(data.items);
        }
      });
    }
  }, [token, setOrderStatuses]);

  return token ? <Outlet /> : <Navigate to="/login" />;
}
