import CustomButton from '@components/Button';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function OrderTutorial() {
  const navigate = useNavigate();

  return (
    <Typography
      component="div"
      sx={{
        textAlign: 'center',
        '> p, div, h5': {
          marginBottom: '30px'
        }
      }}>
      <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
        {"Great! Now let's get a sense of what an order looks like"}
      </Typography>
      <Typography sx={{ color: '#a8a8a8' }}>
        {
          "We like to keep things simple to you can focus on doing what you do best, making great food. Let's see what an order will look like, and how you confirm it."
        }
      </Typography>

      <Typography component="div">
        <CustomButton
          default
          variant="contained"
          sx={{ mr: 3 }}
          onClick={() => navigate('/go-live')}>
          Skip
        </CustomButton>
        <CustomButton variant="contained" color="primary">
          See Order Tutorial
        </CustomButton>
      </Typography>
    </Typography>
  );
}
