import CustomButton from '@components/Button';
import Header from '@components/Header';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid2,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  Typography
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function HelpAndSupport() {
  const navigator = useNavigate();

  const [issue, setIssue] = useState<string>('');
  const [additionalDetails, setAdditionalDetails] = useState<string>('');

  return (
    <Box sx={{ height: '100dvh', overflowX: 'hidden' }}>
      <Typography
        sx={{
          py: 2,
          px: 2,
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center'
        }}>
        <IconButton
          aria-label="add to shopping cart"
          onClick={() => {
            navigator(-1);
          }}>
          <CloseIcon sx={{ fontSize: 30 }} />
        </IconButton>
        <Typography variant="h6" fontWeight={'bold'}>
          LIVE CHAT
        </Typography>
      </Typography>
      <Divider variant="middle" sx={{ width: '100%' }} />
      <Box
        sx={{
          width: '100%',
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 5,
          py: 5
        }}>
        <Typography sx={{ fontSize: 18, textAlign: 'center', fontWeight: 'bold' }}>
          Just afew things before we connect you with expert...
        </Typography>

        <Box sx={{ width: '100%', position: 'relative', my: 3 }}>
          <Divider variant="middle" sx={{ width: '100%' }} />
          <Typography
            sx={{
              p: 1,
              position: 'absolute',
              left: '49%',
              right: 0,
              bottom: 0,
              top: '-20px',
              width: '50px',
              height: '50px',
              background: '#fff',
              color: 'red',
              textAlign: 'center'
            }}>
            <ChatBubbleIcon sx={{ fontSize: 30, color: 'red' }} />
          </Typography>
        </Box>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">What is your issue?</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={issue}
            label="What is your issue?"
            onChange={(e) => {
              setIssue(e.target.value);
            }}>
            <MenuItem value={'Cancel order'}>Cancel order</MenuItem>
            <MenuItem value={'Change/Update order'}>Change/Update order</MenuItem>
            <MenuItem value={"Credits/Promotions didn't apply"}>
              Credits/Promotions didn&apos;t apply
            </MenuItem>
            <MenuItem value={'Missing/Incorrect item'}>Missing/Incorrect item</MenuItem>
            <MenuItem value={'Never received order'}>Never received order</MenuItem>
            <MenuItem value={'Order status inquiry'}>Order status inquiry</MenuItem>
          </Select>
        </FormControl>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'end',
            alignItems: 'end'
          }}>
          <TextareaAutosize
            placeholder="Please provide additional details..."
            style={{
              width: '100%',
              border: '2px solid transparent',
              borderBottom: '2px solid lightgrey',
              paddingInline: 10,
              paddingBlock: 5
            }}
            value={additionalDetails}
            onChange={(e) => {
              if (e.target.value.length <= 200) {
                setAdditionalDetails(e.target.value.slice(0, 200));
              }
            }}
            minRows={2}
          />
          <Typography sx={{ fontSize: 16 }}>{`${additionalDetails?.length}/200`}</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
            gap: 2
          }}>
          <CustomButton default variant="contained" sx={{ px: 5 }} onClick={() => navigator(-1)}>
            Back
          </CustomButton>
          <CustomButton variant="contained" color="primary" sx={{ px: 10 }}>
            Chat
          </CustomButton>
        </Box>
      </Box>
    </Box>
  );
}
