import type { StateCreator } from 'zustand';

export type RestaurantType = {
  id: string;
  name?: string;
  about?: string;
  lat?: number;
  lng?: number;
  street_address?: string;
  city?: string;
  province?: string;
  country?: string;
  zip_code?: string;
  image?: string;
  contact_name?: string;
  contact_email?: string;
  contact_phone?: string;
  delivery_fee?: number;
  delivery_time?: string;
  website?: string;
  auto_confirm_order?: boolean;
  new_order_alter_volume?: string;
  dasher_arriving_alert?: boolean;
  status: string;
};

export interface RestaurantSlice {
  restaurant: RestaurantType | null;
  addRestaurant: (restaurant: RestaurantType) => void;
}

export const restaurantSlice: StateCreator<RestaurantSlice> = (set) => ({
  restaurant: null,
  addRestaurant: (restaurant: RestaurantType) =>
    set(() => ({
      restaurant
    }))
});
