import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Avatar, Box, Button, Typography } from '@mui/material';
import React, { useState } from 'react';

const ImageUploadComp = ({
  handleSelectedFile,
  multiple,
  image
}: {
  handleSelectedFile: (files: File) => void;
  multiple?: boolean;
  image?: string;
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const files = Array.from(event.target.files);
      setSelectedFile(files[0]);
      handleSelectedFile(files[0]);
    }
  };

  const handleClickImage = () => {
    const inpEle = document.getElementById('menu-image');
    if (inpEle) {
      inpEle.click();
    }
  };

  return (
    <Box
      sx={{
        border: '2px dashed #ccc',
        borderRadius: '8px',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
        margin: '20px auto',
        minHeight: '300px'
      }}>
      {selectedFile || image ? (
        <Typography variant="body2" sx={{ marginTop: '10px' }}>
          <Avatar
            variant="square"
            sx={{ width: '100%', height: '100%' }}
            src={
              selectedFile
                ? URL.createObjectURL(selectedFile)
                : image
                ? `${process.env.REACT_APP_STORAGE_URL}/${image}`
                : ''
            }
            alt={selectedFile?.name || 'menu-image'}
            onClick={() => handleClickImage()}
          />
        </Typography>
      ) : (
        <>
          <CloudUploadIcon sx={{ fontSize: 50, color: '#ccc' }} />
          <Typography variant="body1" sx={{ margin: '10px 0' }}>
            Drag & Drop files here
          </Typography>
          <Typography variant="body2" color="textSecondary">
            or
          </Typography>
          <Button
            variant="contained"
            component="label"
            onClick={() => handleClickImage()}
            sx={{ marginTop: '10px', backgroundColor: '#1E90FF' }}>
            Browse Files
          </Button>
        </>
      )}
      <input
        id="menu-image"
        type="file"
        multiple={multiple}
        accept=".png,.jpg,.jpeg"
        hidden
        onChange={handleFileChange}
      />
    </Box>
  );
};

export default ImageUploadComp;
