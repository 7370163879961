import CustomButton from '@components/Button';
import Modal from '@components/Modal';
import { Box, Grid2, Typography } from '@mui/material';
import { useState } from 'react';

import { KitchenStatus } from '.';

export type AdditionalPrepTimeForBusyType = {
  minutes: number;
};

export type AdditionalPrepTimeForPauseType = {
  minutes: number;
};

export default function KitchenStatusesModal(props: {
  showKitchenStatusModal: boolean;
  setshowKitchenStatusModal: any;
  kitchenStatuses: KitchenStatus[];
  onChangeStatus: any;
  orderingStatus: string;
}) {
  const {
    showKitchenStatusModal,
    setshowKitchenStatusModal,
    kitchenStatuses,
    onChangeStatus,
    orderingStatus
  } = props;

  const [additionalPrepTimeForBusy, setAdditionalPrepTimeForBusy] = useState<
    AdditionalPrepTimeForBusyType[]
  >([{ minutes: 5 }, { minutes: 10 }, { minutes: 15 }, { minutes: 20 }]);
  const [additionalPrepTimeForPause, setAdditionalPrepTimeForPause] = useState<
    AdditionalPrepTimeForPauseType[]
  >([{ minutes: 10 }, { minutes: 20 }, { minutes: 30 }, { minutes: 60 }]);
  const [status, setStatus] = useState<string>(orderingStatus);

  const [selectedBusyTime, setSelectedBusyTime] = useState<number>(5);
  const [selectedPauseTime, setSelectedPauseTime] = useState<number>(10);

  return (
    <Modal
      title={'Kitchen Status'}
      open={showKitchenStatusModal}
      closeModal={() => {
        setshowKitchenStatusModal(false);
      }}
      maxWidth="md"
      fullScreen={false}
      content={
        <>
          <Grid2 container spacing={3} py={5}>
            {kitchenStatuses?.map((kitchenStatus: KitchenStatus) => {
              return (
                <Grid2 key={kitchenStatus.title}>
                  <CustomButton
                    sx={{
                      background: '#fff',
                      color: '#000',
                      boxShadow: kitchenStatus.title === status ? 'none' : '0px 5px 10px lightgrey',
                      height: 150,
                      border:
                        kitchenStatus.title === status
                          ? '3px solid #475EDC'
                          : '3px solid transparent',
                      py: 2,
                      bgcolor: kitchenStatus.title === status ? '#475EDC33' : '#ffffff'
                    }}
                    onClick={() => {
                      setStatus(kitchenStatus?.title);
                      // setshowKitchenStatusModal(false);
                    }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                        borderLeft: `5px solid ${kitchenStatus.color}`,
                        paddingInline: 5
                      }}>
                      <Typography
                        sx={{
                          fontSize: 18,
                          fontWeight: 'bold'
                        }}>
                        {kitchenStatus.title}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 14,
                          fontWeight: 'bold',
                          textAlign: 'left',
                          mt: 2
                        }}>
                        {kitchenStatus.description}
                      </Typography>
                    </Box>
                  </CustomButton>
                </Grid2>
              );
            })}
          </Grid2>
          {/* Busy Status */}
          {status === 'busy' && (
            <Grid2 size={12} spacing={2} container sx={{ mt: 5 }}>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 'bold',
                  width: '100%'
                }}>
                How much additional prep time do you need?
              </Typography>
              {additionalPrepTimeForBusy?.map((time, i) => {
                return (
                  <Grid2 key={i} size={3}>
                    <CustomButton
                      sx={{
                        width: '100%',
                        background: '#fff',
                        color: '#000',
                        display: 'flex',
                        flexDirection: 'column',
                        boxShadow:
                          time.minutes === selectedBusyTime ? 'none' : '0px 5px 10px lightgrey',
                        height: 150,
                        border:
                          time.minutes === selectedBusyTime
                            ? '3px solid #475EDC'
                            : '3px solid transparent',
                        py: 2,
                        bgcolor: time.minutes === selectedBusyTime ? '#475EDC33' : '#ffffff',
                        textTransform: 'none'
                      }}
                      onClick={() => {
                        setSelectedBusyTime(time.minutes);
                      }}>
                      <Typography
                        sx={{
                          fontSize: 16,
                          fontWeight: 'bold',
                          textAlign: 'center'
                        }}>
                        + {time.minutes} mins
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 16,
                          fontWeight: 'bold',
                          textAlign: 'center',
                          color: 'grey'
                        }}>
                        prep time
                      </Typography>
                    </CustomButton>
                  </Grid2>
                );
              })}
            </Grid2>
          )}

          {/* Paused Status */}
          {status === 'paused' && (
            <Grid2 size={12} spacing={2} container sx={{ mt: 5 }}>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 'bold',
                  width: '100%'
                }}>
                How much additional prep time do you need?
              </Typography>
              {additionalPrepTimeForBusy?.map((time, i) => {
                return (
                  <Grid2 key={i} size={3}>
                    <CustomButton
                      sx={{
                        width: '100%',
                        background: '#fff',
                        color: '#000',
                        display: 'flex',
                        flexDirection: 'column',
                        boxShadow:
                          time.minutes === selectedPauseTime ? 'none' : '0px 5px 10px lightgrey',
                        height: 150,
                        border:
                          time.minutes === selectedPauseTime
                            ? '3px solid #475EDC'
                            : '3px solid transparent',
                        py: 2,
                        bgcolor: time.minutes === selectedPauseTime ? '#475EDC33' : '#ffffff',
                        textTransform: 'none'
                      }}
                      onClick={() => {
                        setSelectedPauseTime(time.minutes);
                      }}>
                      <Typography
                        sx={{
                          fontSize: 16,
                          fontWeight: 'bold',
                          textAlign: 'center'
                        }}>
                        Paused for
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 16,
                          fontWeight: 'bold',
                          textAlign: 'center',
                          color: 'grey'
                        }}>
                        {time.minutes} mins
                      </Typography>
                    </CustomButton>
                  </Grid2>
                );
              })}
            </Grid2>
          )}

          {/* Accepting Status */}
          {status === 'Accepting' && (
            <Box sx={{ width: '100%', mt: 5 }}>
              <Typography
                sx={{
                  fontSize: 16,
                  fontWeight: 'bold'
                }}>
                Your kitchen status is currently normal:
              </Typography>
              <ul>
                <li>
                  <Typography
                    sx={{
                      fontSize: 16,
                      fontWeight: 'normal'
                    }}>
                    Customers are seeing standard deliver ETAs on GogBig
                  </Typography>
                </li>
              </ul>
            </Box>
          )}
          {/* Busy Status */}
          {status === 'busy' && (
            <Box sx={{ width: '100%', mt: 5 }}>
              <Typography
                sx={{
                  fontSize: 16,
                  fontWeight: 'bold'
                }}>
                Updating you kitchen status to Busy will:
              </Typography>
              <ul>
                <li>
                  <Typography
                    sx={{
                      fontSize: 16,
                      fontWeight: 'normal'
                    }}>
                    Add {selectedBusyTime} mins to your delivery ETA on GoBig
                  </Typography>
                </li>
                <li>
                  <Typography
                    sx={{
                      fontSize: 16,
                      fontWeight: 'normal'
                    }}>
                    St a timer to change your status on Normal after {selectedBusyTime} mins
                  </Typography>
                </li>
              </ul>
            </Box>
          )}

          {/* Paused Status */}
          {status === 'paused' && (
            <Box sx={{ width: '100%', mt: 5 }}>
              <Typography
                sx={{
                  fontSize: 16,
                  fontWeight: 'bold'
                }}>
                Updating your kitchen status to Pause will:
              </Typography>
              <ul>
                <li>
                  <Typography
                    sx={{
                      fontSize: 16,
                      fontWeight: 'normal'
                    }}>
                    Stop all new orders from GoBig for {selectedPauseTime} mins
                  </Typography>
                </li>
                <li>
                  <Typography
                    sx={{
                      fontSize: 16,
                      fontWeight: 'normal'
                    }}>
                    St a timer to change your status on Normal after {selectedPauseTime} mins
                  </Typography>
                </li>
              </ul>
            </Box>
          )}

          <CustomButton
            sx={{ width: '100%', mt: 3 }}
            variant="contained"
            color="primary"
            onClick={() => onChangeStatus(status)}>
            Update Status
          </CustomButton>
        </>
      }
    />
  );
}
