import CustomButton from '@components/Button';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function EditMenuSuggestion() {
  const navigate = useNavigate();

  return (
    <Typography
      component="div"
      sx={{
        textAlign: 'center',
        '> p, div, h5': {
          marginBottom: '30px'
        }
      }}>
      <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
        You can edit your menu at www.GoBig.co/merchant/
      </Typography>
      <Typography sx={{ color: '#a8a8a8' }}>
        {"Can't get to merchant portal now? Give us a call to assist in 888-888-8888"}
      </Typography>

      <Typography component="div">
        <CustomButton
          variant="contained"
          color="primary"
          onClick={() => navigate('/order-tutorial')}>
          Okay
        </CustomButton>
      </Typography>
    </Typography>
  );
}
