import CustomButton from '@components/Button';
import Modal from '@components/Modal';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CallIcon from '@mui/icons-material/Call';
import { Box, Divider, IconButton, Link, Typography } from '@mui/material';
import React, { useState } from 'react';

import { IssueWithOrderActionType } from '.';

export default function IssueWithOrderModal(props: {
  showIssueWithOrderModal: boolean;
  setShowIssueWithOrderModal: any;
  issueWithOrderActions: IssueWithOrderActionType[];
}) {
  const { showIssueWithOrderModal, setShowIssueWithOrderModal, issueWithOrderActions } = props;

  const [showContactUserModal, setShowContactUserModal] = useState(false);

  return (
    <Modal
      title={<strong> Issue with Order</strong>}
      open={showIssueWithOrderModal}
      closeModal={() => {
        setShowIssueWithOrderModal(false);
      }}
      maxWidth="md"
      fullScreen={false}
      content={
        <React.Fragment>
          <Box
            sx={{
              width: '100%',
              mt: 3,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                bgcolor: '#EDF2F3',
                p: 2
              }}>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 'bold'
                }}>
                Contacts
                <Box
                  sx={{
                    bgcolor: '#fff',
                    p: 2,
                    width: '300px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}>
                  <Typography>
                    <Typography
                      sx={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        color: 'grey'
                      }}>
                      Customer
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 16,
                        fontWeight: 'bold'
                      }}>
                      Test
                    </Typography>
                  </Typography>
                  <IconButton
                    sx={{ color: '#000', bgcolor: 'lightgrey' }}
                    aria-label="add to shopping cart"
                    onClick={() => {
                      setShowContactUserModal(true);
                    }}>
                    <CallIcon />
                  </IconButton>
                </Box>
              </Typography>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 'bold',
                  textAlign: 'end'
                }}>
                <Typography
                  sx={{
                    fontSize: 16,
                    fontWeight: 'bold',
                    color: 'grey'
                  }}>
                  24/7 Support English 888-888-8888
                </Typography>
                <Typography
                  sx={{
                    fontSize: 16,
                    fontWeight: 'bold',
                    color: 'grey'
                  }}>
                  Spanish 888-888-8888
                </Typography>
              </Typography>
            </Box>
            {issueWithOrderActions?.map((action, i) => {
              return (
                <React.Fragment key={i}>
                  <CustomButton
                    sx={{
                      width: '100%',
                      background: '#fff',
                      boxShadow: 'none',
                      color: '#000',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      py: 3
                    }}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      action.action();
                      setShowIssueWithOrderModal(false);
                    }}>
                    <Typography
                      sx={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        color: '#000'
                      }}>
                      {action.title}{' '}
                      {action.title === 'Cancel Order' && (
                        <span style={{ color: 'orange', paddingLeft: '100px' }}>
                          Not available after order confirmation
                        </span>
                      )}
                    </Typography>
                    <ArrowForwardIosIcon
                      sx={{
                        fontSize: 20,
                        color: 'grey'
                      }}
                    />
                  </CustomButton>
                  {i < issueWithOrderActions.length - 1 && (
                    <Divider variant="middle" sx={{ width: '100%' }} />
                  )}
                </React.Fragment>
              );
            })}
          </Box>
          {/* Contact User */}
          <Modal
            title={<></>}
            open={showContactUserModal}
            closeModal={() => {
              setShowContactUserModal(false);
            }}
            hideCloseButton={true}
            maxWidth="sm"
            fullScreen={false}
            content={
              <React.Fragment>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    gap: 4,
                    p: 4
                  }}>
                  <Typography sx={{ fontSize: 18, fontWeight: 'bold' }}>
                    Test 617-123-4567
                  </Typography>
                  <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>
                    This number will only work if you call from 617-123-4567.If you are no longer
                    using that phone number, please contact support
                  </Typography>
                  <Typography
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'end'
                    }}>
                    <CustomButton
                      sx={{
                        width: '100px',
                        bgcolor: 'red',
                        color: '#fff',
                        fontWeight: 'bold'
                      }}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setShowContactUserModal(false);
                      }}>
                      OK
                    </CustomButton>
                  </Typography>
                </Box>
              </React.Fragment>
            }
          />
        </React.Fragment>
      }
    />
  );
}
