import Loader from "@components/Loader";
import CloseIcon from "@mui/icons-material/Close";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
        overflowX: "hidden !important",
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

export default function Modal({
    open,
    closeModal,
    title,
    content,
    actions,
    fullScreen = true,
    maxWidth = "lg",
    loading,
    hideCloseButton,
}: {
    open: boolean;
    closeModal: () => void;
    title: string | JSX.Element;
    content: JSX.Element;
    actions?: JSX.Element;
    fullScreen?: boolean;
    maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
    loading?: boolean;
    hideCloseButton?: boolean;
}) {
    return (
        <BootstrapDialog
            onClose={() => closeModal()}
            aria-labelledby="customized-dialog-title"
            open={open}
            fullScreen={fullScreen}
            maxWidth={maxWidth}
            fullWidth
        >
            <DialogTitle
                sx={{
                    m: 0,
                    p: 2,
                    " img": {
                        marginTop: "-5px",
                        marginRight: "10px",
                    },
                }}
                id="customized-dialog-title"
            >
                {title}
            </DialogTitle>
            {!hideCloseButton && (
                <IconButton
                    aria-label="close"
                    onClick={() => closeModal()}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.primary.main,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            )}

            <DialogContent dividers>
                {loading ? <Loader /> : content}
            </DialogContent>
            {actions && (
                <DialogActions
                    sx={{ background: "#ebeff3", padding: "15px !important" }}
                >
                    {actions}
                </DialogActions>
            )}
        </BootstrapDialog>
    );
}
