import CustomButton from '@components/Button';
import { Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function ConfirmMenu() {
  const navigate = useNavigate();
  return (
    <Typography
      component="div"
      sx={{
        textAlign: 'center',
        '> p, div, h5': {
          marginBottom: '30px'
        }
      }}>
      <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
        Is your menu correct?
      </Typography>
      <Typography sx={{ color: '#a8a8a8' }}>
        You can always temporarily disable item on your menu if you run out of an ingredient or can
        no longer make something for the day.
      </Typography>

      <Typography component="div">
        <CustomButton
          default
          variant="contained"
          sx={{ mr: 3 }}
          onClick={() => navigate('/manage-menu')}>
          I have Few Edits
        </CustomButton>
        <CustomButton
          variant="contained"
          color="primary"
          onClick={() => navigate('/edit-menu-suggestion')}>
          Yes, Looks Great
        </CustomButton>
      </Typography>
    </Typography>
  );
}
