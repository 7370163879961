import CustomButton from '@components/Button';
import FavoriteIcon from '@mui/icons-material/Favorite';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import { Box, Typography } from '@mui/material';

export default function NoOrder() {
  return (
    <Typography
      component="div"
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}>
      {/* Can be replaced with a actual asset */}
      <Box
        sx={{
          borderBottom: '3px solid lightgrey',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          p: 5
        }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <ShoppingBasketIcon
            sx={{
              fontSize: 120,
              color: 'lightgrey',
              position: 'absolute'
            }}
          />
          <Box
            sx={{
              width: 40,
              height: 40,
              background: '#000',
              zIndex: 3,
              display: 'flex',
              marginTop: '30px',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 9999
            }}>
            <FavoriteIcon
              sx={{
                fontSize: 30,
                color: 'lightgrey',
                zIndex: 3
              }}
            />
          </Box>
        </Box>
        <Typography
          sx={{
            fontSize: 18,
            fontWeight: 'bold',
            textAlign: 'center',
            mt: 3
          }}>
          No Active Orders
        </Typography>
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 'bold',
            textAlign: 'center',
            color: '#a8a8a8',
            mt: 2,
            paddingInline: 6
          }}>
          Checkot see if your tablet is working properly by checking atest order on the Settings
          page
        </Typography>
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
          p: 5
        }}>
        <Typography
          sx={{
            fontSize: 18,
            fontWeight: 'bold',
            textAlign: 'center',
            mt: 3
          }}>
          Prevent order issues from happening
        </Typography>
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 'bold',
            textAlign: 'center',
            color: '#a8a8a8',
            paddingInline: 6
          }}>
          Pay attention to the item with the tag below to reduce commonly reported customer issue
        </Typography>
        <CustomButton
          variant="contained"
          sx={{
            background: '#FF000033',
            borderRadius: '99999px',
            color: '#000',
            fontWeight: 'bold',
            paddingInline: 5
          }}>
          <HomeRepairServiceIcon
            sx={{
              fontSize: 30,
              color: 'red',
              marginRight: 1
            }}
          />{' '}
          Double check this item Often Missing
        </CustomButton>
      </Box>
    </Typography>
  );
}
