import OrdersNotifications from '@components/OrdersNotifications';
import AuthLayout from '@layouts/auth';
import Container from '@layouts/container';
import AppBar from '@layouts/navbar';
import { Typography } from '@mui/material';
import ActiveOrders from '@pages/ActiveOrders';
import EditMenuSuggestion from '@pages/EditMenuSuggestion';
import GoLive from '@pages/GoLive';
import HelpAndSupport from '@pages/HelpAndSupport';
import Login from '@pages/Login';
import ManageMenu from '@pages/ManageMenu';
import OrderHistory from '@pages/OrdersHistory';
import OrderTutorial from '@pages/OrderTutorial';
import ConfirmMenu from '@pages/ReviewMenu';
import ReviewMenuConfirmation from '@pages/ReviewMenuConfirmation';
import Settings from '@pages/Settings';
import Signup from '@pages/Signup';
import StoreHours from '@pages/StoreHours';
import { LoadScript } from '@react-google-maps/api';
import ProtectedRoute from '@routes/protectedRoute';
import { useCommonStore } from '@store/index';
import React from 'react';
import { Navigate, Route, Routes as RouterRoutes } from 'react-router-dom';

const CompWithNavBar = (props: { children: React.ReactNode }) => {
  const selectedItem = useCommonStore((state) => state.selectedNavItem);
  return (
    <div style={{ maxWidth: '1024', background: '#edf2f3' }}>
      <AppBar selectedItem={selectedItem} />
      <Typography component="div">{props.children}</Typography>
    </div>
  );
};

export default function Routes() {
  return (
    <LoadScript
      id="script-loader"
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY!}
      language="en"
      region="us"
      libraries={[]}>
      <RouterRoutes>
        <Route path="/" element={<Navigate replace to="/login" />} />
        <Route
          path="login"
          element={
            <AuthLayout>
              <Login />
            </AuthLayout>
          }
        />
        <Route
          path="register"
          element={
            <AuthLayout colSize={8} marginTop="2%">
              <Signup />
            </AuthLayout>
          }
        />
        <Route path="/" element={<ProtectedRoute />}>
          <Route
            path="/review-menu-confirmation"
            element={
              <Container>
                <ReviewMenuConfirmation />
              </Container>
            }
          />{' '}
          <Route
            path="/confirm-menu"
            element={
              <Container>
                <ConfirmMenu />
              </Container>
            }
          />
          <Route
            path="/edit-menu-suggestion"
            element={
              <Container>
                <EditMenuSuggestion />
              </Container>
            }
          />
          <Route
            path="/order-tutorial"
            element={
              <Container>
                <OrderTutorial />
              </Container>
            }
          />
          <Route
            path="/go-live"
            element={
              <Container>
                <GoLive />
              </Container>
            }
          />
          <Route
            path="/manage-menu"
            element={
              <CompWithNavBar>
                <ManageMenu />
              </CompWithNavBar>
            }
          />
          <Route
            path="/active-orders"
            element={
              <CompWithNavBar>
                <ActiveOrders />
              </CompWithNavBar>
            }
          />
          <Route
            path="/order-history"
            element={
              <CompWithNavBar>
                <OrderHistory />
              </CompWithNavBar>
            }
          />
          <Route
            path="/settings"
            element={
              <CompWithNavBar>
                <Settings />
              </CompWithNavBar>
            }
          />
          <Route path="/help-support" element={<HelpAndSupport />} />
          <Route
            path="/store-hours"
            element={
              <CompWithNavBar>
                <StoreHours />
              </CompWithNavBar>
            }
          />
        </Route>
      </RouterRoutes>
      <OrdersNotifications />
    </LoadScript>
  );
}
