import Header from "@components/Header";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import InventoryIcon from "@mui/icons-material/Inventory";
import { Box, Grid2, Link, Typography } from "@mui/material";
import React, { useState } from "react";

import AddSpecialHoursModal from "./AddSpecialHoursModal";

export default function StoreHours() {
    const [showAddSpecialHoursModal, setshowAddSpecialHoursModal] =
        useState<boolean>(false);

    return (
        <React.Fragment>
            <Box sx={{ height: "100dvh" }}>
                <Header>
                    <Typography
                        component="div"
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                    >
                        <Typography variant="h6" fontWeight={"bold"}>
                            Store Hours
                        </Typography>
                    </Typography>
                </Header>
                <Grid2 container spacing={2} sx={{ px: 3, height: "90%" }}>
                    <Grid2 size={5} sx={{ bgcolor: "#fff", p: 4 }}>
                        <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                            Regular Menu Hours
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 18,
                                fontWeight: "bold",
                                color: "#bebebe",
                                mt: 2,
                            }}
                        >
                            These are the hours your store is available on GoBig
                        </Typography>
                        <Box
                            sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                mt: 5,
                                cursor: "pointer",
                            }}
                        >
                            <Typography
                                sx={{ fontSize: 20, fontWeight: "bold" }}
                            >
                                {"Juan's Burritos (Al Day)"}
                            </Typography>
                            <Typography
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    mt: 3,
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: 18,
                                        fontWeight: "bold",
                                        color: "#bebebe",
                                    }}
                                >
                                    Monday
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 18,
                                        fontWeight: "bold",
                                        color: "#bebebe",
                                    }}
                                >
                                    2PM . 4:15PM
                                </Typography>
                            </Typography>
                        </Box>
                    </Grid2>
                    <Grid2 size={7} sx={{ bgcolor: "#fff", p: 4 }}>
                        <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                            Special Hours and Closures
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 18,
                                fontWeight: "bold",
                                color: "#bebebe",
                                width: "80%",
                                mt: 2,
                            }}
                        >
                            Add special hours or closures for holidays, special
                            events, or other exceptional events. This wil
                            temporarily replace your regular menu hours
                        </Typography>
                        <Box
                            sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: 2,
                                mt: 5,
                                py: 5,
                            }}
                        >
                            <InventoryIcon
                                sx={{ fontSize: 30, color: "#6977F5" }}
                            />
                            <Typography
                                sx={{
                                    fontSize: 18,
                                    fontWeight: "bold",
                                    color: "#bebebe",
                                }}
                            >
                                No upcoming special hours or closures
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 18,
                                    fontWeight: "bold",
                                    color: "#bebebe",
                                    width: "80%",
                                }}
                            >
                                <Link
                                    href="#"
                                    underline="none"
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                    onClick={() => {
                                        setshowAddSpecialHoursModal(true);
                                    }}
                                >
                                    <span>
                                        <AddCircleOutlineIcon
                                            sx={{ fontSize: 20, mt: "5px" }}
                                        />
                                    </span>{" "}
                                    Add special hours or closures
                                </Link>
                            </Typography>
                        </Box>
                    </Grid2>
                </Grid2>
            </Box>
            <AddSpecialHoursModal
                showAddSpecialHoursModal={showAddSpecialHoursModal}
                setshowAddSpecialHoursModal={setshowAddSpecialHoursModal}
                onConfirm={() => {
                    setshowAddSpecialHoursModal(false);
                }}
            />
        </React.Fragment>
    );
}
