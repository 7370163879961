import { Box, Switch, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React, { useState } from "react";

// Custom Switch with sliding background
const CustomSwitch = styled(Box)(({ theme }) => ({
  width: "100%",
  height: 40,
  padding: 3,
  borderRadius: 4,
  backgroundColor: "lightgrey",
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  "& .slider": {
    position: "absolute",
    top: 4,
    left: 4,
    width: "49.5%",
    height: "80%",
    borderRadius: 4,
    backgroundColor: "#ffffff",
    transition: "all 0.4s ease",
  },
  "& .MuiSwitch-root": {
    position: "absolute",
    left: 5,
    right: 5,
    width: "100%",
    height: "100%",
    zIndex: 1,
    opacity: 0,
  },
}));

const SlidingSwitch = (props: {
  checked: boolean;
  handleCheck: () => void;
}) => {
  const { checked, handleCheck } = props;

  return (
    <CustomSwitch>
      <Box
        className="slider"
        sx={{
          transform: checked ? "translateX(100%)" : "translateX(0)",
          width: "100%",
          cursor: "pointer",
        }}
      />
      <Typography
        sx={{
          zIndex: 2,
          flex: 1,
          textAlign: "center",
          fontWeight: "bold",
          color: !checked ? "#475EDC" : "#000",
          cursor: "pointer",
        }}
        onClick={handleCheck}
      >
        Open with 5 special hours
      </Typography>
      <Typography
        sx={{
          zIndex: 2,
          flex: 1,
          textAlign: "center",
          fontWeight: "bold",
          color: checked ? "#475EDC" : "#000",
          cursor: "pointer",
        }}
        onClick={handleCheck}
      >
        Closed
      </Typography>

      <Switch checked={checked} onChange={handleCheck} />
    </CustomSwitch>
  );
};

export default SlidingSwitch;
