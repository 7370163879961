import CustomSnackbar from '@components/Snackbar';
import { Typography } from '@mui/material';
import Routes from '@routes/index';
import { useEffect, useState } from 'react';
import OneSignal from 'react-onesignal';

function App() {
  // const [initialized, setInitialized] = useState(false);
  const [eventTriggered, setEventTriggered] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      OneSignal.init({
        appId: 'f17670e4-5c7d-40e5-8d46-bf4b21f7b2bb'
      });
    }
  }, []);

  useEffect(() => {
    const handleNotification = () => {
      handlePlay(); // Play sound when notification is received
      // You can also manage notifications here if needed
    };

    // Assuming you set up a service worker for push notifications
    window.addEventListener('notificationreceived', handleNotification);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener('notificationreceived', handleNotification);
    };
  }, []);

  const handlePlay = () => {
    const audio = new Audio('/notification.wav');
    audio.play().catch((error) => {
      console.error('Error playing sound:', error);
    });
  };

  useEffect(() => {
    if (eventTriggered) {
      handlePlay();
    }
  }, [eventTriggered]);

  return (
    <Typography component="div" sx={{ height: '100vh', margin: 'auto' }}>
      <Routes />;
      <CustomSnackbar />
    </Typography>
  );
}

export default App;
