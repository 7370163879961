import { Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import CustomButton from '../../components/Button';

export default function GoLive() {
  const navigate = useNavigate();
  return (
    <Typography
      component="div"
      sx={{
        textAlign: 'center',
        '> p, div, h5': {
          marginBottom: '30px'
        }
      }}>
      <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
        You are ready to go live!
      </Typography>
      <Typography sx={{ color: '#a8a8a8' }}>We are so excited to have you on GoBig</Typography>

      <Typography component="div">
        <CustomButton default variant="contained" sx={{ mr: 3 }}>
          See Order Tutorial
        </CustomButton>
        <CustomButton
          variant="contained"
          color="primary"
          onClick={() => {
            navigate('/active-orders');
          }}>
          Go Live
        </CustomButton>
      </Typography>
    </Typography>
  );
}
