import { CircularProgress, Typography } from '@mui/material';
import React from 'react';

export default function Loader() {
  return (
    <Typography
      component="div"
      sx={{ width: '100%', display: 'inline-block', textAlign: 'center', padding: '10px' }}>
      <CircularProgress size={50} />
    </Typography>
  );
}
